import React,{Fragment} from 'react';

import Zoom from 'react-reveal/Zoom';
import { BiWorld } from "react-icons/bi";
import { AiOutlineShoppingCart,AiFillHtml5 } from "react-icons/ai";
import { FaDigitalOcean } from "react-icons/fa";
import { GrGraphQl } from "react-icons/gr";
import { SiDatocms } from "react-icons/si";
import { BsCardImage } from "react-icons/bs";
import { MdSettingsApplications,MdOutlineDesignServices,MdAnimation } from "react-icons/md";
import { TiFlashOutline } from "react-icons/ti";


// import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';


const ECommerce = () => {
    return (
        <Fragment>
            <section className='ecommerce-section'>
                <Zoom>
                <div className='row'>
                    <h2>Mobile Application Development</h2>
                    <div className='ecommerce-content'>
                        <p>Crafting native and cross-platform mobile applications that engage users and enhance brand visibility. Whether it's iOS, Android, or hybrid development, we have the expertise to turn your app ideas into reality, from initial concept to app store launch.</p>
                    </div>
                    <div className='services-grid'>
                            <Slide left>
                            <div className='services-grid-inner'>
                                <div className='services-icon'>
                                    <span><MdOutlineDesignServices/></span>
                                </div>
                                <div className='services-content'>
                                    <h3>Native App Development (Swift, Kotlin)</h3>
                                    {/* <p>With our custom web designers, creative artists and new media engineers, we help you....</p> */}
                                </div>
                            </div>
                            </Slide>
                            <Slide top>
                            <div className='services-grid-inner'>
                                <div className='services-icon'>
                                    <span><BsCardImage/></span>
                                </div>
                                <div className='services-content'>
                                    <h3>Cross-Platform Development (React Native, Flutter)</h3>
                                    {/* <p>We have great expertise in professional web design templates and all resources to provide</p> */}
                                </div>
                            </div>
                            </Slide>
                            <Slide right>
                            <div className='services-grid-inner'>
                                <div className='services-icon'>
                                    <span><AiOutlineShoppingCart/></span>
                                </div>
                                <div className='services-content'>
                                    <h3>User Interface Design</h3>
                                    {/* <p>Banking and finance, insurance, department store or car rental, pharmaceutical, media..</p> */}
                                </div>
                            </div>
                            </Slide>
                            <Slide left>
                            <div className='services-grid-inner'>
                                <div className='services-icon'>
                                    <span><FaDigitalOcean/></span>
                                </div>
                                <div className='services-content'>
                                    <h3>App Store Optimization (ASO)</h3>
                                    {/* <p>Website Re-designing, improvement and timely changes are essential for every website.</p> */}
                                </div>
                            </div>
                            </Slide>
                            <Slide bottom>
                            <div className='services-grid-inner'>
                                <div className='services-icon'>
                                    <span><TiFlashOutline/></span>
                                </div>
                                <div className='services-content'>
                                    <h3>Continuous Integration and Delivery (CI/CD)</h3>
                                    {/* <p>By perfectly blending programming expertise, market skills and artistic talent, we are..</p> */}
                                </div>
                            </div>
                            </Slide>
                            {/* <Slide right>
                            <div className='services-grid-inner'>
                                <div className='services-icon'>
                                    <span><AiFillHtml5/></span>
                                </div>
                                <div className='services-content'>
                                    <h3>PSD to HTML</h3>
                                    <p>PSD to HTML5 conversion service providers, Shonod has acquired influential position.</p>
                                </div>
                            </div>
                            </Slide> */}
                            
                    </div>
                </div>

                </Zoom>
            </section>
        </Fragment>
    );
};

export default ECommerce;