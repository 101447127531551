import React,{Fragment} from 'react';

import { BiWorld } from "react-icons/bi";
import { AiOutlineShoppingCart,AiFillHtml5 } from "react-icons/ai";
import { FaDigitalOcean } from "react-icons/fa";
import { TbDeviceMobileMessage } from "react-icons/tb";
import { MdSettingsApplications,MdOutlineDesignServices,MdAnimation } from "react-icons/md";
import { GrGraphQl } from "react-icons/gr";
import { SiDatocms } from "react-icons/si";
import { BsCardImage } from "react-icons/bs";
import { HiCode } from "react-icons/hi";


import { TiFlashOutline } from "react-icons/ti";

import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';

const WebDevelopment = () => {
    return (
        <Fragment>
            <section className='web-design-section'>
                <Zoom >
                <div className='row' >
                <h2>Web Application Development</h2>
                    <div className='web-design-content'>
                    <p>
                    
                    Building dynamic and interactive web applications that deliver seamless user experiences across devices. Our team leverages cutting-edge technologies and frameworks to create scalable and secure web solutions that drive business growth.
                    
                    </p>
                    </div>

                    <div className='services-grid'>
                        <Slide right>
                        <div className='services-grid-inner'>
                            <div className='services-icon'>
                                <span><AiOutlineShoppingCart/></span>
                            </div>
                            <div className='services-content'>
                                <h3>Frontend Development (HTML5, CSS3, JavaScript)</h3>
                                {/* <p>Shonod is specialized in database driven and interactive E-commerce Development. Present day online search for current information needs higher level of personalization...</p> */}
                            </div>
                        </div>
                        </Slide>
                        <Slide left>
                        <div className='services-grid-inner'>
                            <div className='services-icon'>
                                <span><MdOutlineDesignServices/></span>
                            </div>
                            <div className='services-content'>
                                <h3>Backend Development (Node.js, Django, Laravel,React js)</h3>
                                {/* <p>As a leading web development company, We have extensive web design and web development skills blended with the quality essence of expertise must have element to help you establish your Internet...</p> */}
                            </div>
                        </div>
                        </Slide>
                        <Slide top>
                        <div className='services-grid-inner'>
                            <div className='services-icon'>
                                <span><HiCode/></span>
                            </div>
                            <div className='services-content'>
                                <h3>Database Integration (MySQL, MongoDB, PostgreSQL)</h3>
                                {/* <p>Shonod is one of the leading software service providers in India that offers outsourcing for PHP development in India. Our team of experienced PHP developers has enabled us to ...</p> */}
                            </div>
                        </div>
                        </Slide>
                        
                        <Slide left>
                        <div className='services-grid-inner'>
                            <div className='services-icon'>
                                <span><BiWorld/></span>
                            </div>
                            <div className='services-content'>
                                <h3>Responsive Design</h3>
                                {/* <p>Shonod was an early adapter of the .NET framework. We were perhaps the first to present a paper on the .NET framework in a conference hosted by Wire trade and Microsoft in Mauritius...</p> */}
                            </div>
                        </div>
                        </Slide>
                        <Slide bottom>
                        <div className='services-grid-inner'>
                            <div className='services-icon'>
                                <span><TiFlashOutline/></span>
                            </div>
                            <div className='services-content'>
                                <h3>Performance Optimization</h3>
                                {/* <p>Shonod was an early adapter of the .NET framework. We were perhaps the first to present a paper on the .NET framework in a conference hosted by Wire trade and Microsoft in Mauritius...</p> */}
                            </div>
                        </div>
                        </Slide>
                        
                    </div>
                </div>
                </Zoom>
            </section>
        </Fragment>
    );
};

export default WebDevelopment;