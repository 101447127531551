import React,{Fragment} from 'react';


import { BiWorld } from "react-icons/bi";
import { AiOutlineShoppingCart,AiFillHtml5 } from "react-icons/ai";
import { FaDigitalOcean } from "react-icons/fa";
import { TbDeviceMobileMessage } from "react-icons/tb";
import { MdSettingsApplications,MdOutlineDesignServices,MdAnimation } from "react-icons/md";
import { GrGraphQl } from "react-icons/gr";
import { SiDatocms } from "react-icons/si";
import { BsCardImage } from "react-icons/bs";

import { TiFlashOutline } from "react-icons/ti";

import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';

const WebDesign = () => 
{
    return (
        <Fragment>
            <section className='web-design-section'>
                <Zoom >
                <div className='row'>
                    <h2>Custom Software Development</h2>
                    <div className='web-design-content'>
                    <p>
                    Tailored solutions to meet the unique needs and requirements of businesses across various industries. From conceptualization to deployment, our team of experts ensures that every aspect of the software aligns with your specific objectives.
                    </p>
                    </div>

                    {/* <div>
                        <h4 className='content-heading'>Features</h4>
                        <ul>
                            <li className='content'>Requirement Analysis and Planning</li>
                            <li className='content'>UI/UX Design</li>
                            <li className='content'>Agile Development Methodlogy</li>
                            <li className='content'>Quality Assurance and Testing</li>
                            <li className='content'>Deployment and Maintenance</li>
                        </ul>
                    </div> */}

                    <div className='services-grid'>
                        <Slide left>
                        <div className='services-grid-inner'>
                            <div className='services-icon'>
                                <span><MdOutlineDesignServices/></span>
                            </div>
                            <div className='services-content'>
                                <h3>Requirement Analysis and Planning</h3>
                                {/* <p>With our custom web designers, creative artists and new media engineers, we help you....</p> */}
                            </div>
                        </div>
                        </Slide>
                        <Slide top>
                        <div className='services-grid-inner'>
                            <div className='services-icon'>
                                <span><BsCardImage/></span>
                            </div>
                            <div className='services-content'>
                                <h3>UI/UX Design</h3>
                                {/* <p>We have great expertise in professional web design templates and all resources to provide</p> */}
                            </div>
                        </div>
                        </Slide>
                        <Slide right>
                        <div className='services-grid-inner'>
                            <div className='services-icon'>
                                <span><AiOutlineShoppingCart/></span>
                            </div>
                            <div className='services-content'>
                                <h3>Agile Development Methodology</h3>
                                {/* <p>Banking and finance, insurance, department store or car rental, pharmaceutical, media..</p> */}
                            </div>
                        </div>
                        </Slide>
                        <Slide left>
                        <div className='services-grid-inner'>
                            <div className='services-icon'>
                                <span><FaDigitalOcean/></span>
                            </div>
                            <div className='services-content'>
                                <h3>Quality Assurance and Testing</h3>
                                {/* <p>Website Re-designing, improvement and timely changes are essential for every website.</p> */}
                            </div>
                        </div>
                        </Slide>
                        <Slide bottom>
                        <div className='services-grid-inner'>
                            <div className='services-icon'>
                                <span><TiFlashOutline/></span>
                            </div>
                            <div className='services-content'>
                                <h3>Deployment and Maintenance</h3>
                                {/* <p>By perfectly blending programming expertise, market skills and artistic talent, we are..</p> */}
                            </div>
                        </div>
                        </Slide>
                        {/* <Slide right>
                        <div className='services-grid-inner'>
                            <div className='services-icon'>
                                <span><AiFillHtml5/></span>
                            </div>
                            <div className='services-content'>
                                <h3>PSD to HTML</h3>
                                <p>PSD to HTML5 conversion service providers, Shonod has acquired influential position.</p>
                            </div>
                        </div>
                        </Slide> */}
                        
                    </div>
                </div>
                </Zoom>
            </section>
        </Fragment>
    );
};

export default WebDesign;