import React,{Fragment} from 'react'

import { BiWorld,BiRocket } from "react-icons/bi";
import { AiOutlineShoppingCart,AiFillHtml5,AiOutlineSound,AiOutlineMail } from "react-icons/ai";
import { MdSettingsApplications,MdOutlineDesignServices,MdAnimation } from "react-icons/md";
import { HiCode } from "react-icons/hi";
import { TiFlashOutline } from "react-icons/ti";
import { BsGraphUp,BsLink45Deg } from "react-icons/bs";


// import pic1 from '../asset/image/web-app.png'
// import pic2 from '../asset/image/web-app2.png'

import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';

const ApplicationDev = () => {
    return (
        <Fragment>
        <section className='digital-marketing-section'>
            <Zoom>
            <div className='row'>
            <h2>Machine Learning Model Development</h2>
            <p className='des'>
            Developing custom machine learning models tailored to specific business needs and objectives. Our team of data scientists and engineers specializes in building predictive analytics models, recommendation systems, image and video recognition algorithms, and other ML applications to drive data-driven decision-making and business growth.
            </p>
            {/* <h3 className='title'>We provide following Digital Marketing Services:</h3> */}
            <div className='services-grid'>
                    <Slide right>
                    <div className='services-grid-inner'>
                        <div className='services-icon'>
                            <span><BsGraphUp/></span>
                        </div>
                        <div className='services-content'>
                            <h3>Data Preprocessing and Feature Engineering</h3>
                            {/* <p>We are providing Website Maintenance Services professional in this field. We had seen many like doing this that updating...</p> */}
                        </div>
                    </div>
                    </Slide>
                    <Slide left>
                    <div className='services-grid-inner'>
                        <div className='services-icon'>
                            <span><AiOutlineSound/></span>
                        </div>
                        <div className='services-content'>
                            <h3>Model Training and Evaluation</h3>
                            {/* <p>Social media is all about establishing huge user network, sharing their content, opinions, views and encouraging interaction...</p> */}
                        </div>
                    </div>
                    </Slide>
                    <Slide top>
                    <div className='services-grid-inner'>
                        <div className='services-icon'>
                            <span><BiRocket/></span>
                        </div>
                        <div className='services-content'>
                            <h3>Hyperparameter Tuning and Optimization</h3>
                            {/* <p>PPC Program search engines guarantee top listings for selected keywords. These keywords or search phrases are the ones relevant...</p> */}
                        </div>
                    </div>
                    </Slide>
                    
                    <Slide left>
                    <div className='services-grid-inner'>
                        <div className='services-icon'>
                            <span><BsLink45Deg/></span>
                        </div>
                        <div className='services-content'>
                            <h3>Deployment and Integration with Existing Systems</h3>
                            {/* <p>Your link popularity is one of the factors that determine the ranking of your site and Search engines find your website through...</p> */}
                        </div>
                    </div>
                    </Slide>
                    <Slide right>
                    <div className='services-grid-inner'>
                        <div className='services-icon'>
                            <span><AiOutlineMail/></span>
                        </div>
                        <div className='services-content'>
                            <h3>Continuous Model Monitoring and Maintenance</h3>
                            {/* <p>Marketing' in simpler terms is defined as the management process for identifying, anticipating, and satisfying customer...</p> */}
                        </div>
                    </div>
                    </Slide>
                    
                </div>
            </div>
            </Zoom>
        </section>
    </Fragment>
    );
};

export default ApplicationDev;