import React,{Fragment} from 'react';

import { BiWorld } from "react-icons/bi";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { FaDigitalOcean } from "react-icons/fa";
import { TbDeviceMobileMessage } from "react-icons/tb";
import { MdSettingsApplications,MdOutlineDesignServices,MdAnimation } from "react-icons/md";
import { GrGraphQl } from "react-icons/gr";
import { SiDatocms } from "react-icons/si";

import {Link} from 'react-router-dom'

import Slide from 'react-reveal/Slide';

const Services = () => 
{
    return (
        <Fragment>
            <section className='services-section'>
                <div className='row'>
                    <h2>What We Offer</h2>
                    <div className='services-grid'>
                        <Slide left>
                        <Link className="services-link" to='/customSoftware'>
                        <div className='services-grid-inner'>
                            <div className='services-icon'>
                                <span><MdOutlineDesignServices/></span>
                            </div>
                            <div className='services-content'>
                                <h3>Custom Software Development</h3>
                                <p>Tailored solutions to meet the unique needs and requirements of businesses across various industries.....</p>
                            </div>
                        </div>
                        </Link>
                        </Slide>
                        <Slide top>
                        <Link className="services-link" to='/webApplication'>
                        <div className='services-grid-inner'>
                            <div className='services-icon'>
                                <span><BiWorld/></span>
                            </div>
                            <div className='services-content'>
                                <h3>Web Application Development</h3>
                                <p>Building dynamic and interactive web applications that deliver seamless user experiences across devices....</p>
                            </div>
                        </div>
                        </Link>
                        </Slide>
                        <Slide right>
                        <Link className="services-link" to='/mobileApplication'>
                        <div className='services-grid-inner'>
                            <div className='services-icon'>
                                <span><AiOutlineShoppingCart/></span>
                            </div>
                            <div className='services-content'>
                                <h3>Mobile App Development</h3>
                                <p>Crafting native and cross-platform mobile applications that engage users and enhance brand visibility....</p>
                            </div>
                        </div>
                        </Link>
                        </Slide>
                        <Slide left>
                        <Link className="services-link" to='/nlp'>
                        <div className='services-grid-inner'>
                            <div className='services-icon'>
                                <span><FaDigitalOcean/></span>
                            </div>
                            <div className='services-content'>
                                <h3>Natural Language Processing (NLP) Solutions</h3>
                                <p>Leveraging advanced NLP algorithms and models to analyze and understand human language....</p>
                            </div>
                        </div>
                        </Link>
                        </Slide>
                        <Slide bottom>
                        <Link className="services-link" to='/machineLearning'>
                        <div className='services-grid-inner'>
                            <div className='services-icon'>
                                <span><MdSettingsApplications/></span>
                            </div>
                            <div className='services-content'>
                                <h3>Machine Learning Model Development</h3>
                                <p>Developing custom machine learning models tailored to specific business needs and objectives.....</p>
                            </div>
                        </div>
                        </Link>
                        </Slide>
                        <Slide right>
                        <Link className="services-link" to='/computerVision'>
                        <div className='services-grid-inner'>
                            <div className='services-icon'>
                                <span><TbDeviceMobileMessage/></span>
                            </div>
                            <div className='services-content'>
                                <h3>Computer Vision Solutions</h3>
                                <p>Harnessing the power of computer vision to analyze and interpret visual data from images and videos....</p>
                            </div>
                        </div>
                        </Link>
                        </Slide>
                        <Slide left>
                        <Link className="services-link" to='/predectiveAnalysis'>
                        <div className='services-grid-inner'>
                            <div className='services-icon'>
                                <span><GrGraphQl/></span>
                            </div>
                            <div className='services-content'>
                                <h3>Predictive Analytics and Forecasting</h3>
                                <p>Utilizing AI and statistical modeling techniques to analyze historical data and make predictions about future outcomes....</p>
                            </div>
                        </div>
                        </Link>
                        </Slide>
                        <Slide bottom>
                        <Link className="services-link" to='/cmsDev'>
                        <div className='services-grid-inner'>
                            <div className='services-icon'>
                                <span><SiDatocms/></span>
                            </div>
                            <div className='services-content'>
                                <h3>AI-Powered Personalization</h3>
                                <p>Delivering personalized experiences and recommendations to users based on their preferences, behavior, and historical interactions.....</p>
                            </div>
                        </div>
                        </Link>
                        </Slide>
{/* 
                        <Slide right>
                        <Link className="services-link" to='/arContentDev'>
                        <div className='services-grid-inner'>
                            <div className='services-icon'>
                                <span><MdAnimation/></span>
                            </div>
                            <div className='services-content'>
                                <h3>3D Animation, AR VR Content development</h3>
                                <p>creating 3D characters, 3D object models and scenes, setting visual effects, developing UI/UX design, creating 2D/3D animations.
                                Creating mobile and desktop apps using augmented reality technologies, like face recognition, Facebook & Instagram face masks design, 3D/2D object recognition, GEO identification, complex back-end solutions.
                                Creation of full-fledged VR projects for diverse purposes, regardless of the complexity, including projects for mobile, desktop, wireless VR, as well as solutions for web resources.</p>
                            </div>
                        </div>
                        </Link>
                        </Slide> */}
                        
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default Services;